import { Box } from '@mui/material';
import { MetaTags } from 'components/common';
import { NextPage } from 'next';
import { Achievements } from './Achievements';
import { Hero } from './Hero';
import { JoinNow } from './JoinNow';
import MediaPage from './MediaPage';
import { OurPartners } from './OurPartners';
import { TestimonialCarousel } from './Testimonial/TestimonialCarousel';
import { WhyPod } from './WhyPod';

const InvestPage: NextPage = () => {
  return (
    <Box>
      <MetaTags
        title="Invest in Indian Startups on POD"
        description="Invest in promising early-stage Indian startups. Become an investor now!"
        imageUrl={`${process.env.NEXT_PUBLIC_FRONTEND_URL}pod-logo.png`}
        url={process.env.NEXT_PUBLIC_FRONTEND_URL!}
        keywords="Invest Indian Startups"
      />
      <Hero />
      <WhyPod />
      <Achievements />
      {/* <Testimonial /> */}
      <TestimonialCarousel />
      <OurPartners />
      <MediaPage />
      <JoinNow />
    </Box>
  );
};

export default InvestPage;
